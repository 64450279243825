import * as React from "react";

const Close = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Fermer</title>
    <path
      d="M20.4852 4.92984L19.071 3.51563L11.9999 10.5867L4.92886 3.51562L3.51465 4.92984L10.5857 12.0009L3.51465 19.072L4.92886 20.4862L11.9999 13.4151L19.071 20.4862L20.4852 19.072L13.4141 12.0009L20.4852 4.92984Z"
      fill="currentColor"
    />
  </svg>
);

export default Close;
