import * as React from "react";
import { Link } from "gatsby";

import Logo from "./logo";
import PrimaryMenu from "./primary-menu";
import SecondaryMenu from "./secondary-menu";
import MobileMenu from "./mobile-menu";

const Header = ({ issue }) => (
  <header className={`header${issue ? " header-issue" : ""}`}>
    <div className="container">
      <div className="header-logo">
        <Link to="/">
          <Logo />
        </Link>
        {issue && (
          <Link to={`/numeros/${issue.slug}/`}>
            N<sup>o</sup> {issue.number}
          </Link>
        )}
      </div>
      <div className="header-menu">
        <PrimaryMenu issue={issue} />
        {issue && <SecondaryMenu />}
        <MobileMenu issue={issue} />
      </div>
    </div>
  </header>
);

export default Header;
