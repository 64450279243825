import * as React from "react";
import { Link } from "gatsby";

import Hamburger from "./hamburger";
import Close from "./close";

const SecondaryMenu = () => {
  const [menu, setMenu] = React.useState(null);

  function toggleMenu() {
    setMenu(!menu ? true : null);
  }

  React.useEffect(() => {
    function closeMenu(event) {
      if (event.key === "Escape") setMenu(null);
    }

    window.addEventListener("keydown", closeMenu);
    return () => window.removeEventListener("keydown", closeMenu);
  }, []);

  return (
    <div className="header-menu-secondary">
      <button
        onClick={toggleMenu}
        aria-controls="header-menu-secondary"
        aria-label="Menu : site"
      >
        <Hamburger />
      </button>
      <div
        className="header-panel"
        id="header-menu-secondary"
        aria-label="Menu : site"
        aria-modal={menu}
        tabIndex={-1}
        role={menu ? "dialog" : null}
      >
        <button onClick={toggleMenu}>
          <Close />
        </button>
        <nav>
          <ul>
            <li>
              <Link to="/" onClick={toggleMenu}>
                Accueil
              </Link>
            </li>
            <li>
              <Link to="/numeros/" onClick={toggleMenu}>
                Tous les numéros
              </Link>
            </li>
            <li>
              <Link to="/actualites/" onClick={toggleMenu}>
                Actualité économique du CSE
              </Link>
            </li>
            <li>
              <a
                href="https://www.csemichelin.fr"
                target="_blank"
                rel="noreferrer"
              >
                Site CSE Michelin
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <button
        className="header-backdrop"
        onClick={toggleMenu}
        aria-hidden="true"
      />
    </div>
  );
};

export default SecondaryMenu;
