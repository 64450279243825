import * as React from "react";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ issue, children }) => (
  <div className="wrapper">
    <Header issue={issue} />
    <main>{children}</main>
    <Footer />
  </div>
);

export default Layout;
