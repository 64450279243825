import * as React from "react";
import { Link } from "gatsby";

import Hamburger from "./hamburger";
import Logo from "./logo";
import Close from "./close";
import ChevronRight from "./chevron-right";
import ChevronLeft from "./chevron-left";
import ArticleImage from "./article-image";

const Submenu = ({ title, articles, menu, toggleMenu }) => {
  const [submenu, setSubmenu] = React.useState(null);

  function toggleSubmenu() {
    setSubmenu(!submenu ? true : null);
  }

  React.useEffect(() => {
    if (!menu) setSubmenu(null);

    function closeSubmenu(event) {
      if (event.key === "Escape") setSubmenu(null);
    }

    window.addEventListener("keydown", closeSubmenu);
    return () => window.removeEventListener("keydown", closeSubmenu);
  }, [menu]);

  if (!articles) return null;

  return (
    <li className="header-submenu">
      <button
        onClick={toggleSubmenu}
        aria-controls={`submenu-${articles[0].id}`}
      >
        <span>{title}</span>
        <ChevronRight />
      </button>
      <div
        className="header-panel"
        id={`submenu-${articles[0].id}`}
        aria-label={`Menu : ${title}`}
        aria-modal={submenu}
        tabIndex={-1}
        role={submenu ? "dialog" : null}
      >
        <div className="header-panel-content">
          <button onClick={toggleSubmenu}>
            <ChevronLeft />
            <span>{title}</span>
          </button>
          <ul>
            {articles.map((article) => (
              <li key={article.id}>
                <Link to={`/articles/${article.slug}/`} onClick={toggleMenu}>
                  {article.title}
                </Link>
                <figure>
                  <ArticleImage articleImage={article.featuredImage} />
                </figure>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </li>
  );
};

const MobileMenu = ({ issue }) => {
  const [menu, setMenu] = React.useState(null);

  function toggleMenu() {
    setMenu(!menu ? true : null);
  }

  React.useEffect(() => {
    function closeMenu(event) {
      if (event.key === "Escape") setMenu(null);
    }

    window.addEventListener("keydown", closeMenu);
    return () => window.removeEventListener("keydown", closeMenu);
  }, []);

  return (
    <div className="header-menu-mobile">
      <button onClick={toggleMenu} aria-controls="menu" aria-label="Menu">
        <Hamburger />
      </button>
      <div
        className="header-panel"
        id="header-menu-mobile"
        aria-label="Menu"
        aria-modal={menu}
        tabIndex={-1}
        role={menu ? "dialog" : null}
      >
        <div className="header-panel-bar">
          <div className="header-logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <button onClick={toggleMenu}>
            <Close />
          </button>
        </div>
        <div className="header-panel-content">
          {issue && (
            <nav aria-label={`Menu : ${issue.title}`}>
              <ul>
                <Submenu
                  title="Dans votre CSE"
                  articles={issue.news}
                  menu={menu}
                  toggleMenu={toggleMenu}
                />
                <Submenu
                  title="Dossier"
                  articles={issue.dossier}
                  menu={menu}
                  toggleMenu={toggleMenu}
                />
                <Submenu
                  title="Voyager"
                  articles={issue.travel}
                  menu={menu}
                  toggleMenu={toggleMenu}
                />
                <Submenu
                  title="Découvrir"
                  articles={issue.discover}
                  menu={menu}
                  toggleMenu={toggleMenu}
                />
                <Submenu
                  title="Se divertir"
                  articles={issue.revel}
                  menu={menu}
                  toggleMenu={toggleMenu}
                />
                <Submenu
                  title="Sur un coin de l’étagère"
                  articles={issue.mediaLibrary}
                  menu={menu}
                  toggleMenu={toggleMenu}
                />
              </ul>
            </nav>
          )}
          <nav aria-label="Menu : site">
            <ul>
              <li>
                <Link to="/" onClick={toggleMenu}>
                  Accueil
                </Link>
              </li>
              <li>
                <Link to="/numeros/" onClick={toggleMenu}>
                  Tous les numéros
                </Link>
              </li>
              <li>
                <Link to="/actualites/" onClick={toggleMenu}>
                  Actualité économique du CSE
                </Link>
              </li>
              <li>
                <a
                  href="https://www.csemichelin.fr"
                  target="_blank"
                  rel="noreferrer"
                >
                  Site CSE Michelin
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="header-backdrop" aria-hidden="true" />
    </div>
  );
};

export default MobileMenu;
