import * as React from "react";
import { Link } from "gatsby";

import ArticleImage from "./article-image";

const Submenu = ({ title, articles }) => {
  const columnCount = articles?.length > 3 ? 4 : 3;

  if (!articles) return null;

  return (
    <li className="header-submenu">
      <Link to={`/articles/${articles[0].slug}/`}>{title}</Link>
      <div className="header-panel">
        <div className="container">
          <ul style={{ "--column-count": columnCount }}>
            {articles.map((article) => (
              <li key={article.id}>
                <Link to={`/articles/${article.slug}/`}>{article.title}</Link>
                <figure>
                  <ArticleImage articleImage={article.featuredImage} />
                </figure>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="header-backdrop" aria-hidden="true" />
    </li>
  );
};

const PrimaryMenu = ({ issue }) => {
  return (
    <nav
      className="header-menu-primary"
      aria-label={issue ? `Menu : ${issue.title}` : "Menu : site"}
    >
      {issue ? (
        <ul>
          <Submenu title="Dans votre CSE" articles={issue.news} />
          <Submenu title="Dossier" articles={issue.dossier} />
          <Submenu title="Voyager" articles={issue.travel} />
          <Submenu title="Découvrir" articles={issue.discover} />
          <Submenu title="Se divertir" articles={issue.revel} />
          <Submenu
            title="Sur un coin de l’étagère"
            articles={issue.mediaLibrary}
          />
        </ul>
      ) : (
        <ul>
          <li>
            <Link to="/numeros/">Tous les numéros</Link>
          </li>
          <li>
            <Link to="/actualites/">Actualité économique du CSE</Link>
          </li>
          <li>
            <a
              className="button"
              href="https://www.csemichelin.fr"
              target="_blank"
              rel="noreferrer"
            >
              Site CSE Michelin
            </a>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default PrimaryMenu;
