import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

const Seo = ({ title, description, image, color, bodyClass }) => {
  const data = useStaticQuery(query);
  const { pathname } = useLocation();
  const issueColor = color || data.issues.nodes?.[0].color || `#626262`;
  const defaultTitle = data.seo?.title;
  const defaultDescription = data.seo?.description?.description;
  const defaultImage = data.seo?.image;
  const siteUrl = data.seo?.siteUrl;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname}`,
  };

  const imageUrl = `${siteUrl}${getSrc(seo.image.localFile.childImageSharp)}`;
  const imageSize = seo.image.file.details.image;

  return (
    <Helmet
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${defaultTitle}`}
      htmlAttributes={{ lang: "fr", dir: "ltr" }}
      bodyAttributes={bodyClass && { class: bodyClass }}
    >
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={imageSize.width} />
      <meta property="og:image:height" content={imageSize.height} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="theme-color" content={issueColor} />
      <style>
        {`:root {
          --color-issue: ${issueColor};
        }`}
      </style>
    </Helmet>
  );
};

export default Seo;

const query = graphql`
  query {
    seo: contentfulSeo(contentful_id: { eq: "sRBKsJvYOC41HmNNsj3yd" }) {
      title
      siteUrl
      description {
        description
      }
      image {
        file {
          details {
            image {
              width
              height
            }
          }
        }
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              aspectRatio: 1
              quality: 80
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
    issues: allContentfulIssue(
      limit: 1
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        color
      }
    }
  }
`;
