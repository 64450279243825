import * as React from "react";

const Ultro = () => (
  <svg
    width="36"
    height="12"
    viewBox="0 0 36 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Ultrō</title>
    <path d="M34.4229 0H28.4391V2.15788H34.4229V0Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28697 7.93447C5.28697 8.74659 4.80977 9.23388 4.08264 9.23388C3.73425 9.23388 3.46153 9.11014 3.25705 8.85489C3.05252 8.60738 2.94651 8.29802 2.94651 7.93447V3.35571H5.57408e-05V8.4759C-0.00752683 10.1156 0.75751 11.9796 2.90102 11.9796C3.77206 11.9796 4.68101 11.5851 5.27939 10.7576H5.30213V11.8095H8.23343V3.35571H5.28702L5.28697 7.93447Z"
      fill="currentColor"
    />
    <path d="M12.5542 0H9.57747V11.8607H12.5542V0Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9211 0H15.0429L15.0345 3.40704H13.406V5.8975H14.9587V8.80561C14.9587 9.82653 15.2541 10.6155 15.8449 11.1646C16.4281 11.7215 17.2083 11.9999 18.1854 11.9999C18.7989 11.9999 19.3215 11.9226 19.7533 11.7601V9.42432C19.3669 9.45524 19.1094 9.47072 18.9807 9.47072C18.2687 9.47072 17.9127 9.14588 17.9127 8.48846V5.89745H19.829V3.40704H17.9127L17.9211 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1157 3.53853C24.6536 3.70098 24.1916 4.0954 23.8811 4.62909H23.8583V3.40701H20.9346V11.8607H23.8886V8.38024C23.8886 6.94936 24.6006 6.2301 26.017 6.2301C26.2518 6.2301 26.4942 6.25333 26.7365 6.29969L26.7441 3.38383C26.5547 3.34517 26.373 3.32968 26.1988 3.32968C25.8882 3.32968 25.5701 3.38383 25.1157 3.53853Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6443 4.34215C33.8187 3.59192 32.6674 3.12787 31.4631 3.12787C30.2588 3.12787 29.0999 3.59967 28.2591 4.37313C27.4108 5.14658 26.8654 6.30672 26.8654 7.55198C26.8654 8.82041 27.3881 9.96511 28.2061 10.7308C29.0241 11.5043 30.183 11.9683 31.4025 11.9683C32.6371 11.9683 33.8035 11.4811 34.6367 10.6999C35.4699 9.92645 36.0001 8.75083 36.0001 7.4824C36.0001 6.23709 35.4699 5.09238 34.6443 4.34215ZM32.5572 8.82113C32.2466 9.1692 31.8679 9.33933 31.421 9.33933C30.9742 9.33933 30.5879 9.1692 30.2773 8.82113C29.9668 8.4808 29.8077 8.07864 29.8077 7.61459C29.8077 7.15053 29.9592 6.74832 30.2698 6.40804C30.5803 6.07546 30.959 5.90533 31.4135 5.90533C31.8679 5.90533 32.2466 6.07546 32.5572 6.40804C32.8677 6.74837 33.0192 7.15053 33.0192 7.61459C33.0192 8.07864 32.8678 8.4808 32.5572 8.82113Z"
      fill="currentColor"
    />
  </svg>
);

export default Ultro;
