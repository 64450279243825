import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const ArticleImage = ({ articleImage }) => {
  const { seo } = useStaticQuery(query);
  const image = articleImage || seo.image;

  return (
    <GatsbyImage
      image={image.localFile.childImageSharp.gatsbyImageData}
      alt={image.description || ""}
    />
  );
};

export default ArticleImage;

const query = graphql`
  query {
    seo: contentfulSeo(contentful_id: { eq: "sRBKsJvYOC41HmNNsj3yd" }) {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              aspectRatio: 1.5
              quality: 80
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
  }
`;
