import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Logo from "./logo";
import Ultro from "./ultro";

const Footer = () => {
  const data = useStaticQuery(query);
  const issue = data.issues.nodes[0];

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-address">
          <Logo />
          <address>
            Comité Social et Économique Michelin
            <br />
            Place des Carmes-Déchaux
            <br />
            63040 Clermont-Ferrand cedex 9
          </address>
          <p>
            Tél. : <a href="tel:+33473923430">04 73 92 34 30</a>
          </p>
        </div>
        <div className="footer-links">
          <p>
            <strong>Spot Magazine</strong>
          </p>
          <ul>
            <li>
              <Link to="/numeros/">Tous les numéros</Link>
            </li>
            <li>
              <Link to="/actualites/">Actualité économique du CSE</Link>
            </li>
            <li>
              <a
                href="https://www.csemichelin.fr"
                target="_blank"
                rel="noreferrer"
              >
                Site CSE Michelin
              </a>
            </li>
            <li>
              <a
                href="https://csemichelin.bibenligne.fr/opac/oidc/login?oidc=custom"
                target="_blank"
                rel="noreferrer"
              >
                Médiathèque
              </a>
            </li>
            <li>
              <a
                href="https://www.csemichelin.fr/calendrier_liste_evenements_agendas.html"
                target="_blank"
                rel="noreferrer"
              >
                Agenda
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-links">
          <p>
            <strong>Informations</strong>
          </p>
          <ul>
            <li>
              <a href="mailto:accueil@csemichelin.fr">Contact</a>
            </li>
            <li>
              <Link to="/mentions-legales/">Mentions légales</Link>
            </li>
            <li>
              <Link to="/politique-de-confidentialite/">
                Politique de confidentialité
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-issue">
          <p>
            <strong>
              N<sup>o</sup> {issue.number} – {issue.dateRange}
            </strong>
          </p>
          {issue.cover && (
            <Link to={`/numeros/${issue.slug}/`} aria-label={issue.title}>
              <GatsbyImage
                image={issue.cover.localFile.childImageSharp.gatsbyImageData}
                alt={issue.cover.description || ""}
              />
            </Link>
          )}
        </div>
        <p className="footer-legal">
          <small>
            © 2021 site réalisé par{" "}
            <a href="https://ultro.fr" target="_blank" rel="noreferrer">
              <Ultro />
            </a>{" "}
            et{" "}
            <a
              href="http://www.doublesalto.com"
              target="_blank"
              rel="noreferrer"
            >
              Double Salto
            </a>
          </small>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

const query = graphql`
  query {
    issues: allContentfulIssue(
      limit: 1
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        ...issueFields
      }
    }
  }
`;
